import React, { useState } from 'react';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
        section.style.scrollSnapAlign = 'start'; // Always snap to start for header clicks
        section.scrollIntoView({ behavior: 'smooth' });

        // Fix for Firefox to ensure proper snapping
        if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            section.style.scrollSnapAlign = 'none';
            setTimeout(() => {
                section.style.scrollSnapAlign = 'start';
            }, 500); // Adjust timeout if necessary
        }
    }

    setIsOpen(false); // Close the menu for mobile
};

  return (
    <header className="w-full bg-gray-900 py-4 shadow-lg sticky top-0 z-50">
      <div className="container mx-auto flex justify-between items-center px-4">
        <div className="text-white text-3xl font-bold cursor-pointer" onClick={() => handleScrollToSection('home')}>
          Phillip Solis
        </div>
        <nav className="hidden lg:flex space-x-4">
          <button className="text-gray-300 hover:text-white transition" onClick={() => handleScrollToSection('projects')}>Projects</button>
          <button className="text-gray-300 hover:text-white transition" onClick={() => handleScrollToSection('about')}>About</button>
          <button className="text-gray-300 hover:text-white transition" onClick={() => handleScrollToSection('contact')}>Contact</button>
        </nav>
        <button onClick={toggleMenu} className="lg:hidden text-white focus:outline-none">
          {isOpen ? <span>&#x25B2;</span> : <span>&#x25BC;</span>}
        </button>
      </div>

      <div
        className={`lg:hidden bg-gray-900 bg-opacity-90 absolute w-full left-0 py-4 flex flex-col items-center space-y-4 transition-all duration-300 ease-in-out transform ${isOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-10 pointer-events-none'}`}
        style={{ top: '4rem' }}
      >
        <button className="text-gray-300 hover:text-white transition" onClick={() => handleScrollToSection('projects')}>Projects</button>
        <button className="text-gray-300 hover:text-white transition" onClick={() => handleScrollToSection('about')}>About</button>
        <button className="text-gray-300 hover:text-white transition mb-4" onClick={() => handleScrollToSection('contact')}>Contact</button>
      </div>
    </header>
  );
};

export default Header;