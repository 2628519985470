import React, { useEffect } from 'react';
import useScrollFadeIn from '../hooks/useScrollFadeIn';

const Projects = () => {
  useScrollFadeIn();

  useEffect(() => {
    const isMobile = /Android|iPhone|iPad|iPod|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    let lastScrollY = window.scrollY;
    const projectsSection = document.getElementById('projects');

    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const scrollDirection = currentScrollY < lastScrollY ? 'up' : 'down';
      lastScrollY = currentScrollY;

      if (scrollDirection === 'up') {
        projectsSection.style.scrollSnapAlign = 'end'; // Snap to end when scrolling up
      } else {
        projectsSection.style.scrollSnapAlign = 'start'; // Snap to start when scrolling down
      }
    };

    if (isMobile || isSafari) {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div id="projects" className="section py-20 bg-gradient-to-t from-gray-900 to-gray-800 flex flex-col items-center opacity-0">
      <h1 className="text-5xl font-bold mb-8">Projects</h1>
      <ul className="w-full max-w-6xl space-y-8">
        
        {/* InvestAlign Project */}
        <li className="project-card bg-gray-700 p-10 lg:h-[350px] rounded-lg shadow-lg hover:shadow-2xl transition transform hover:scale-105 grid grid-cols-1 lg:grid-cols-2 overflow-hidden">
          <div className="pr-6 flex-1">
            <h2 className="text-3xl font-semibold mb-4">InvestAlign</h2>
            <p className="text-gray-300 text-lg">An in-development Wealth Management Application that allows users to manage their investment, real estate, and retirement accounts.</p>
            <div className="mt-4">
              <a href="https://github.com/Phi1lS/481-Capstone" target="_blank" rel="noopener noreferrer" className="mr-4 bg-gray-600 text-white py-2 px-4 rounded-lg hover:bg-gray-500 transition">GitHub</a>
              <a href="https://www.youtube.com/watch?v=6vs0M9TlStA" target="_blank" rel="noopener noreferrer" className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-500 transition">YouTube</a>
            </div>
          </div>
          <div className="mt-4 lg:mt-0 flex-1">
            <img src="images/InvestAlignSprint2Screens.png" alt="InvestAlign" className="project-image w-full h-full object-contain" />
          </div>
        </li>

        {/* ReMarket Project */}
        <li className="project-card bg-gray-700 p-10 h-[300px] lg:h-[350px] rounded-lg shadow-lg hover:shadow-2xl transition transform hover:scale-105 grid grid-cols-1 lg:grid-cols-2 flex-shrink-0 overflow-hidden">
          <div className="pr-6 flex-1">
            <h2 className="text-3xl font-semibold mb-4">ReMarket</h2>
            <p className="text-gray-300 text-lg">A digital marketplace platform where users can create accounts, list items, and checkout.</p>
            <div className="mt-4">
              <a href="https://github.com/Phi1lS/ReMarket-471-Project" target="_blank" rel="noopener noreferrer" className="mr-4 bg-gray-600 text-white py-2 px-4 rounded-lg hover:bg-gray-500 transition">GitHub</a>
              <a href="https://phi1ls.github.io/ReMarket-471-Project/index.html" target="_blank" rel="noopener noreferrer" className="bg-gray-600 text-white py-2 px-4 rounded-lg hover:bg-gray-500 transition">GitHub Pages</a>
            </div>
          </div>
          <div className="mt-4 lg:mt-0 flex-1">
            <img src="images/ReMarket.png" alt="ReMarket" className="project-image w-full h-full object-contain" />
          </div>
        </li>

        {/* Virtual Reality Conductor Lab Project */}
        <li className="project-card bg-gray-700 p-10 h-[300px] lg:h-[350px] rounded-lg shadow-lg hover:shadow-2xl transition transform hover:scale-105 grid grid-cols-1 lg:grid-cols-2 flex-shrink-0 overflow-hidden">
          <div className="pr-6 flex-1">
            <h2 className="text-3xl font-semibold mb-4">Virtual Reality Conductor Lab</h2>
            <p className="text-gray-300 text-lg">An early-in-development virtual reality choral conducting simulator for the Meta Quest.</p>
            <div className="mt-4">
              <a href="https://youtube.com/link_to_conductor_lab_video" target="_blank" rel="noopener noreferrer" className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-500 transition">YouTube</a>
            </div>
          </div>
          <div className="mt-4 lg:mt-0 flex-1">
            <img src="images/VRConductorLab.jpg" alt="Virtual Reality Conductor Lab" className="project-image w-full h-full object-contain" />
          </div>
        </li>

        {/* Wii Tic-Tac-Toe Project */}
        <li className="project-card bg-gray-700 p-10 h-[300px] lg:h-[350px] rounded-lg shadow-lg hover:shadow-2xl transition transform hover:scale-105 grid grid-cols-1 lg:grid-cols-2 flex-shrink-0 overflow-hidden">
          <div className="pr-6 flex-1">
            <h2 className="text-3xl font-semibold mb-4">Wii Tic-Tac-Toe</h2>
            <p className="text-gray-300 text-lg">A Wii adaptation of Tic-Tac-Toe with an AI that uses a minimax algorithm.</p>
            <div className="mt-4">
              <a href="https://github.com/Phi1lS/WiiTicTacToe" target="_blank" rel="noopener noreferrer" className="mr-4 bg-gray-600 text-white py-2 px-4 rounded-lg hover:bg-gray-500 transition">GitHub</a>
              <a href="https://www.youtube.com/watch?v=Wix70jcytD8" target="_blank" rel="noopener noreferrer" className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-500 transition">YouTube</a>
            </div>
          </div>
          <div className="mt-4 lg:mt-0 flex-1">
            <img src="images/wiitictactoe.png" alt="Wii Tic-Tac-Toe" className="project-image w-full h-full object-contain" />
          </div>
        </li>

        {/* Untitled Video Game Project */}
        <li className="project-card bg-gray-700 p-10 h-[300px] lg:h-[350px] rounded-lg shadow-lg hover:shadow-2xl transition transform hover:scale-105 grid grid-cols-1 lg:grid-cols-2 flex-shrink-0 overflow-hidden">
          <div className="pr-6 flex-1">
            <h2 className="text-3xl font-semibold mb-4">Untitled Video Game</h2>
            <p className="text-gray-300 text-lg">An early-stage video game with an 8-bit chibi art style.</p>
            <div className="mt-4">
              <a href="/game/Jah2.5D.html" target="_blank" rel="noopener noreferrer" className="mr-4 bg-gray-600 text-white py-2 px-4 rounded-lg hover:bg-gray-500 transition">Try it out</a>
              <a href="https://www.youtube.com/watch?v=pRm11SkBV5c" target="_blank" rel="noopener noreferrer" className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-500 transition">YouTube</a>
            </div>
          </div>
          <div className="mt-4 lg:mt-0 flex-1">
            <img src="images/Jah.png" alt="Untitled Video Game" className="project-image w-full h-full object-contain" />
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Projects;